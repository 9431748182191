import * as React from 'react';
import { Trans } from '@lingui/macro';

interface ShowMsgProps {
    msgId: string,
    type: string,
    seq: any
}

interface ShowMsgStates {
    clearSeq: boolean
}

export class ShowMsg extends React.Component<ShowMsgProps, ShowMsgStates> {

    timer: any;
    _clear: boolean;

    constructor(props: Readonly<ShowMsgProps>) {
        super(props);

        this.state = {
            clearSeq: false
        };
        this._clear = false;
        this.timer = null;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        if (this.timer != null)
            clearTimeout(this.timer);
    }

    shouldComponentUpdate(nextProps: any, nextState: any) {
        if (this.state.clearSeq) {
            this._clear = true;
            this.setState({ clearSeq: false });
        }
        if (nextProps.seq != this.props.seq)
            this._clear = false;

        return true;
    }

    public render() {

        if (this._clear) {
            return (<div></div>);
        }

        if (this.timer != null)
            clearTimeout(this.timer);

        this.timer = window.setTimeout(() => {
            this.setState({
                clearSeq: !this.state.clearSeq
            });
        }, 5000);

        return (<div className={this.props.type}><Trans id={this.props.msgId} /></div>
        );
    }
}
