import * as React from 'react';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import { Trans } from '@lingui/macro';
import * as Helper from '../helper/globaljs';
import { ShowMsg } from './ShowMsg';


interface ClipboardProps {
}

interface ClipboardStates {
    isLoading: boolean;
    oNew: boolean;
    oOpen: boolean;
    cpId: string;
    uid: string;
    cpContent: string;
    hasErrors: boolean;
    message: string;
    msgType: string;
    actionSeq: any;
    cpOpenId: string;
}



export class Clipboard extends React.Component<ClipboardProps, ClipboardStates> {

    constructor(props: Readonly<ClipboardProps>) {
        super(props);

        this.state = {
            isLoading: false,
            uid: Helper.createGuid(),
            oNew: false,
            oOpen: false,
            hasErrors: false,
            cpId: '',
            cpContent: '',
            message: '',
            msgType: 'mInfo',
            actionSeq: null,
            cpOpenId: ''
        };
    }

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    newCP = () => {
        this.setState({
            oNew: true,
            oOpen: false,
            message: ''
        });

        this.createNewName();
        this.setState({ cpContent : ''});
    }

    openCP = () => {
        this.setState({
            oOpen: true,
            oNew: false,
            cpContent: '',
            message: ''
        });
    }

    loadCP = () => {
        var h = new Headers();
        h.append('pragma', 'no-cache');
        h.append('cache-control', 'no-cache');
        h.append('Content-Type', 'application/json');

        var reqget = {
            method: 'GET',
            headers: h
        };
        var req = new Request(`api/clipboard?name=${this.state.cpOpenId}`);

        fetch(req, reqget)
            .then(res => res.json())
            .then(c => {
                this.setState({ cpContent: c.c });
            })
            .then(() => this.setState({ isLoading: false }))
            .catch(() => this.setState({ hasErrors: true }));
    }

    deleteCP = () => {
        var h = new Headers();
        h.append('pragma', 'no-cache');
        h.append('cache-control', 'no-cache');
        h.append('Content-Type', 'application/json');

        var reqget = {
            method: 'DELETE',
            headers: h
        };
        var req = new Request(`api/clipboard?name=${this.state.cpOpenId}`);

        fetch(req, reqget)
            .then(res => res.json())
            .then(c => {
                this.setState({ cpContent: c.c });
            })
            .then(() => this.setState({ isLoading: false, msgType: 'mInfo', message: 'ClipboardDeleted', actionSeq: new Date() }))
            .catch(() => this.setState({ hasErrors: true }));
    }

    clipboardChange(v:string) {
        this.setState({ cpContent: v });
    }


    saveClipboard() {
        if (this.state.isLoading)
            return;

        var h = new Headers();
        h.append('pragma', 'no-cache');
        h.append('cache-control', 'no-cache');
        h.append('Content-Type', 'application/json');

        var reqget = {
            method: 'POST',
            headers: h,
            body: JSON.stringify({ c: this.state.cpContent })
        };
        var req = new Request(`api/clipboard/?name=${this.state.cpId}&uid=${this.state.uid}`);

        fetch(req, reqget)
            .then(res => res.json())
            .then(c => {
                if (c.r != true) {
                    throw new DOMException("Error saving");
                }
            })
            .then(() => this.setState({ isLoading: false, msgType: 'mInfo', message: 'ClipboardSaved', actionSeq: new Date() }))
            .catch(() => this.setState({ hasErrors: true, msgType: 'mError', message: 'ClipboardSavingError', actionSeq: new Date() }));
    }

    createNewName() {
        var h = new Headers();
        h.append('pragma', 'no-cache');
        h.append('cache-control', 'no-cache');
        h.append('Content-Type', 'application/json');

        var reqget = {
            method: 'GET',
            headers: h
        };
        var req = new Request(`api/clipboard/code`);

        fetch(req, reqget)
            .then(res => res.json())
            .then(c => {
                this.setState({ cpId: c.c });
            })
            .then(() => this.setState({ isLoading: false }))
            .catch(() => this.setState({ hasErrors: true }));
    }

    render() {
        return (
            <div>
                <div className="row form-group">
                    <div className="col-sm-12 text-center mt-4">
                        <Button className='btn btn-light btn-lg mr-4' onClick={() => this.newCP()} aria-expanded={this.state.oNew} aria-controls="panNew"><Trans>New Clipboard</Trans></Button>
                        <Button className='btn btn-light btn-lg ml-4' onClick={() => this.openCP()} aria-expanded={this.state.oOpen} aria-controls="panOpen"><Trans>Open Clipboard</Trans></Button>
                    </div>
                </div>
                <Collapse isOpen={this.state.oNew}>
                    <Card>
                        <CardBody>
                            <div className='col-sm-12 text-left'>
                                <Trans>Clipboard ID:</Trans> <b>{this.state.cpId}</b>
                            </div>
                        </CardBody>
                    </Card>
                    <div className='mt-4 form-group'>
                        <div className='col-md-6 floatLeft'>
                            <label><Trans>Content to Store</Trans></label>
                        </div>
                        <div className='col-md-6 floatRight text-right'>
                            <label className='mr-4'><ShowMsg msgId={this.state.message} type={this.state.msgType} seq={this.state.actionSeq} /></label>
                            <Button className='btn btn-primary mb-2' onClick={() => this.saveClipboard()} ><Trans>Save Content for 1h</Trans></Button>
                        </div>
                        <textarea value={this.state.cpContent} onChange={(e) => this.clipboardChange(e.target.value)} className='w100' rows={30} maxLength={1024 ^ 2}></textarea>
                        <div className='col-md-12 floatRight text-right'>
                            <label className='mr-4'><ShowMsg msgId={this.state.message} type={this.state.msgType} seq={this.state.actionSeq} /></label>
                            <Button className='btn btn-primary mt-2' onClick={() => this.saveClipboard()} ><Trans>Save Content for 1h</Trans></Button>
                        </div>
                    </div>
                </Collapse>
                <Collapse isOpen={this.state.oOpen}>
                    <Card>
                        <CardBody>
                            <div className='col-sm-12 text-left'>
                                <Trans id='TypeClipboardId' /><input type="text" className="form-control sizeW4 ml-2 font-weight-bold" id="txtClipboardId" maxLength={10} value={this.state.cpOpenId} onChange={(e) => this.setState({ cpOpenId: e.target.value.toUpperCase() })} />
                                <Button className='btn btn-primary ml-2' onClick={() => this.loadCP()}><Trans id='loadClipboard' /></Button>
                                <Button className='btn btn-light ml-4' onClick={() => this.deleteCP()}><Trans id='deleteClipboard' /></Button>
                                <ShowMsg msgId={this.state.message} type={this.state.msgType} seq={this.state.actionSeq} />
                            </div>
                        </CardBody>
                    </Card>
                    <div className='mt-4 form-group'>
                        <textarea value={this.state.cpContent} className='w100' rows={30} maxLength={1024 ^ 2} readOnly></textarea>
                    </div>
                </Collapse>
            </div>
        )
    }
}

