import * as React from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import * as Helper from '../helper/globaljs';


interface RandomLeroProps {
    leroType: string;
    counter: string;
    invalidateSeq: number;
    latimMode: boolean;
    copyOnGenerate: boolean;
    personality: string;
}

interface LeroModel {
    sentences: string[];
    stats: { [id: string]: string };
}

interface RandomLeroStates {
    isLoading: boolean;
    hasErrors: boolean;
    content: string[];
    stats: { [id: string]: string };
}



export class RandomLero extends React.Component<RandomLeroProps, RandomLeroStates> {
    
    constructor(props: Readonly<RandomLeroProps>) {
        super(props);

        this.state = {
            isLoading: false,
            hasErrors: false,
            content: [],
            stats: {}
        };
    }

    content = [''];
    isLoading = false;
    hasErrors = false;

    updateContent(prevProps: any) {
        if (this.state.isLoading)
            return;

        if (prevProps != null && prevProps.invalidateSeq == this.props.invalidateSeq)
            return;

        var h = new Headers();
        h.append('pragma', 'no-cache');
        h.append('cache-control', 'no-cache');

        this.clearSelection();

        var reqget = {
            method: 'GET',
            headers: h,
        };
        var req = new Request(`api/lero/?personality=${this.props.personality}&latimMode=${this.props.latimMode}&leroTypeId=${this.props.leroType}&counter=${this.props.counter}&lang=${Helper.getLanguage()}`);

        fetch(req, reqget)
            .then(res => res.json())
            .then(c => {
                if (this.props.leroType == '1' || this.props.leroType == '4')
                    this.setState({
                        content: c.sentences.map((line: string, idx: number) => <p key={idx.toString()}>{line}</p>) });
                else
                    this.setState({ content: c.sentences });
                this.setState({ stats: c.stats });
                if (this.props.copyOnGenerate) {
                    this.copyToClipboard();
                }
            })
            .then(() => this.setState({ isLoading: false }))
            .catch(() => this.setState({ hasErrors: true }));
    }

    componentDidUpdate(prevProps: any, prevStates: any) {
        this.updateContent(prevProps);
    }

    componentDidMount() {
        this.updateContent(null);
    }

    clearSelection(){
        if (window.getSelection != null && window.getSelection) {
            let sel = window.getSelection();
            if (sel != null)
                sel.removeAllRanges();
        }
    }

    selectText(id: string) {
        this.clearSelection();
        let el = document.getElementById(id); //get element id
        if (window.getSelection && document.createRange) { //Browser compatibility   
            let selx = window.getSelection();
            if (selx != null && selx.toString() == '') { //no text selection
                let sel = selx as Selection;
                let range = document.createRange(); //range object
                range.selectNodeContents(el as Node); //sets Range
                sel.removeAllRanges(); //remove all ranges from selection
                sel.addRange(range);//add Range to a Selection.
            }
        }
    }

    copyToClipboard() {
        this.selectText("txtLeroContent");
        document.execCommand('copy');
        //navigator.clipboard.writeText(this.selectText(areaId));
    }

    render() {
        return (
            <div id="divMainLero">
                <div className="row border-top my-3 font-weight-light">
                    <div className="col-sm-8 text-left"><Trans id="ParagraphsCount">Paragraphs:</Trans> {this.state.stats['nSentences']} | <Trans id="WordsCount">WordsCount:</Trans> {this.state.stats['nWords']} | <Trans id="CharsCount">Chars:</Trans> {this.state.stats['nChars']}
                    </div>
                    <div className="col-sm-4 text-right">
                        <button id="btnGen" type="button" className="btn btn-link" onClick={() => this.copyToClipboard()}><Trans id="SelectAndCopy">Select & Copy</Trans></button>
                    </div>
                </div>
                <div className="row" id="txtLeroContent">
                    {this.state.content}
                </div>
            </div>
        )
    }
}

export default connect()(RandomLero);
