import * as React from 'react';
import { connect } from 'react-redux';
import { RandomLero } from './RandomLero';
import { Trans } from '@lingui/macro';

interface HomeProps {
}

interface LeroType {
    id: string;
    name: string;
}

interface HomeStates {
    leroTypes: LeroType[];
    latimModeEnabled: boolean;
    copyOnGenerate: boolean;
    personality: string;
    currentLeroTypeId: string;
    currentLeroCounter: string;
    leroContentPanel: JSX.Element;
    invalidadeSeq: number;
}



function LeroHomeContent() {
    return (
        <div id='leroPanel' className='col-sm-12 mt-4'>
            <div className="row">
                <div className='col-sm-6'>
                    <h5><Trans id="home-what-title">O que é isso?</Trans></h5>
                    <p><Trans id="home-what-content">
                        Bolsolero é um gerador de lero-lero como alternativa ao Lorem Ipsum, e assim como ele 
                        para ser utilizado por quem precisa de conteúdo para criação de protótipos, diagramação, 
                        testes ou somente por diversão, devido a suas frases memoráveis. Bolsolero foi criado durante 
                        a crise do COVID-19, depois de semanas de quarentena, tempo ocioso e muita desinformação 
                        de várias personalidades. Aqui você poderá rever estas frases arrojadas que serão mantidas 
                        em na história.
                        </Trans>
                    </p>
                </div>
                <div className='col-sm-6'>
                    <h5><Trans id="home-why-title">Por que usar?</Trans></h5>
                    <p><Trans id="home-why-content">
                        Utilize sempre que precisar de conteúdo descontraído e com o alfabeto português/brasileiro 
                        com caracteres normalmente utilizados no preenchimento de formulários. Utilizar conteúdo 
                        fora do alfabeto real que será utilizado pode mascarar falhas especialmente quando as frases 
                        são utilizadas para validação de um sistema de software.
                        </Trans>
                    </p>
                </div>
            </div>
            {
                <div className="row">
                    <div className='col-sm-12'>
                        <h5><Trans id="home-how-title">Como usar?</Trans></h5>
                        <p><Trans id="home-how-content">
                            Basta escolher o tamanho do texto e clicar no botão Gerar Conteúdo. Veja o conteúdo criado e
                            caso prefira alguma outra opção escolha como desejar e gere novamente.
                            </Trans>
                        </p>
                    </div>
                </div>
            }
        </div>
    );
}


export class Home extends React.Component<HomeProps, HomeStates> {

    constructor(props: Readonly<HomeProps>) {
        super(props);
        
        this.state = {
            leroTypes: [
                { id: '0', name: 'Undefined' },
                { id: '1', name: 'Paragraphs' },
                { id: '2', name: 'Words' },
                { id: '3', name: 'Characters' },
                { id: '4', name: 'List' }
            ],
            latimModeEnabled: true,
            copyOnGenerate: true,
            personality: "all",
            invalidadeSeq: 0,
            currentLeroTypeId: '1',
            currentLeroCounter: '50',
            leroContentPanel: <LeroHomeContent />
        };
    }

    generate = () => {
        if (this.state.currentLeroTypeId != '0') {
            this.setState({ invalidadeSeq: this.state.invalidadeSeq + 1 });
            this.setState({
                leroContentPanel: <RandomLero leroType={this.state.currentLeroTypeId} counter={this.state.currentLeroCounter}
                    invalidateSeq={this.state.invalidadeSeq} latimMode={this.state.latimModeEnabled}
                    personality={this.state.personality} copyOnGenerate={this.state.copyOnGenerate} />
            });
        }else
            this.setState({ leroContentPanel: <LeroHomeContent /> });
    };
    
    setLeroType = (leroType: string) => {
        this.setState({ currentLeroTypeId: leroType });
    };

    render() {
        const { leroTypes } = this.state;

        let leroOptions = leroTypes.length > 0
            && leroTypes.map((item, i) => {
                if (i > 0) {
                    return (
                        <div className='form-check-inline' key={"divRadio" + item.id}>
                            <label key={"lbl" + item.id} htmlFor={"inp" + item.id} className="form-check-label">
                                {
                                    i == 1 ?
                                        <input key={"inp" + item.id} type="radio" defaultChecked name="radio-group" className="form-check-input" value={item.id} onClick={this.setLeroType.bind(this, item.id)} />
                                        :
                                        <input key={"inp" + item.id} type="radio" name="radio-group" className="form-check-input" value={item.id} onClick={this.setLeroType.bind(this, item.id)} />
                                }<Trans id={item.name} />
                            </label>
                        </div>
                    )
                }         
            }, this);
        
        return (

            <div>
                <div className='quotation col-sm-12'></div>

                <div className='row'>
                    <div className='col-sm-12 text-center'>
                        <input type="text" className="form-control sizeW3" id="typeCounter" maxLength={6} value={this.state.currentLeroCounter} onChange={(e) => this.setState({ currentLeroCounter: e.target.value})} />
                        {leroOptions}
                    </div>
                    <div className='col-sm-12 mt-2 text-center'>
                        <input className="mr-2" type="checkbox" id="chkLatimMode" checked={this.state.latimModeEnabled} onChange={(e) => this.setState({ latimModeEnabled: e.target.checked })} />
                        <label htmlFor="chkLatimMode"><Trans>Latim Mode</Trans></label>

                        <input className="ml-4 mr-2" type="checkbox" id="chkCopyOnGenerate" checked={this.state.copyOnGenerate} onChange={(e) => this.setState({ copyOnGenerate: e.target.checked })} />
                        <label htmlFor="chkAutoCopy"><Trans>Copy On Generate</Trans></label>
                    </div>
                </div>
                <div className='row mt-4 mb-4'>
                    <div className='col-sm-12 text-center'>
                        <button type='button' className='btn btn-light' onClick={this.generate.bind(this)}><Trans id="GenerateContent">Gerar Conteúdo</Trans></button>
                    </div>
                </div>

                {this.state.leroContentPanel}
            </div>
        );
    }
}

export default connect()(Home);
