import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import { Home } from './components/Home';
import * as Helper from './helper/globaljs'

import './custom.css'
import { EditorLero } from './components/EditorLero';
import { Clipboard } from './components/Clipboard';

import { I18nProvider } from '@lingui/react';

const catalogPt = require('./locales/pt/messages.js');
const catalogEn = require('./locales/en/messages.js');

const catalogs = {
    pt: catalogPt,
    en: catalogEn,
};

export default () => (
    <I18nProvider language={Helper.getLanguage()} catalogs={catalogs}>
        <Layout>
            <Route exact path='/' component={Home}/>
            <Route exact path='/storage/edit' component={EditorLero} />
            <Route exact path='/clipboard' component={Clipboard} />
        </Layout>
    </I18nProvider>
);
