import * as React from 'react';
import { connect } from 'react-redux';
import Row from 'reactstrap/lib/Row';

interface EditorLeroProps {
}

interface EditorLeroStates {
    strContent: string;
    isLoading: boolean;
    hasErrors: boolean;
    token: string;
    cname: string;
}



export class EditorLero extends React.Component<EditorLeroProps, EditorLeroStates> {

    constructor(props: Readonly<EditorLeroProps>) {
        super(props);

        this.state = {
            strContent: '',
            isLoading: false,
            hasErrors: false,
            token: '',
            cname: ''
        };
    }
    
    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate() {
    }

    loadContent() {
        if (this.state.isLoading)
            return;

        var h = new Headers();
        h.append('pragma', 'no-cache');
        h.append('cache-control', 'no-cache');

        var reqget = {
            method: 'GET',
            headers: h,
        };
        var req = new Request(`api/leroeditor/?pwd=` + this.state.token + `&cname=` + this.state.cname);

        fetch(req, reqget)
            .then(res => {
                this.setState({ isLoading: true });
                return res.json();
            })
            .then(c => {
                this.setState({ strContent: c.content });
            })
            .then(() => this.setState({ isLoading: false }))
            .catch(() => this.setState({ hasErrors: true, isLoading: false }));
    }

    saveContent = () => {
        if (this.state.isLoading)
            return;

        let search = window.location.search;
        let params = new URLSearchParams(search);

        var h = new Headers();
        h.append('pragma', 'no-cache');
        h.append('content-type', 'application/json');
        h.append('cache-control', 'no-cache');

        var reqget = {
            method: 'POST',
            headers: h,
            body: JSON.stringify(this.state.strContent)
        };

        var req = new Request(`api/leroeditor/?pwd=` + this.state.token + `&cname=` + this.state.cname);

        this.setState({ isLoading: true });
        fetch(req, reqget)
            .then(res => {
                this.setState({ hasErrors: res.status != 200 });
            })
            .then(() => this.setState({ isLoading: false }))
            .catch(() => this.setState({ hasErrors: true, isLoading: false }));
    }

    render() {
        return (
            <div>
                <div className="row form-group">
                    <label id="lblFile" htmlFor="txtFile" className="form-check-label">Content's Name</label>
                    <input type="text" className="form-control" id="txtFile" maxLength={40} value={this.state.cname} onChange={(e) => this.setState({ cname: e.target.value })} />
                    <label id="lblToken" htmlFor="txtToken" className="form-check-label">Token</label>
                    <input type="password" className="form-control" id="txtToken" maxLength={40} value={this.state.token} onChange={(e) => this.setState({ token: e.target.value })} />
                    <button type='button' className='btn btn-light' onClick={() => this.loadContent()}>Load Content</button>
                </div>
                <div className="row">
                    <button type='button' className='btn btn-light' onClick={() => this.saveContent()}>Update Content</button>
                </div>
                <div className="row">
                    <div>{this.state.isLoading}</div>
                    <textarea className="form-control" rows={20} id="txtEditor" value={this.state.strContent} onChange={(e) => this.setState({ strContent: e.target.value })} />
                </div>
                <div className="row">
                    <button type='button' className='btn btn-light' onClick={() => this.saveContent()}>Update Content</button>
                </div>
            </div>
        )
    }
}

export default connect()(EditorLero);
