import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import * as Helper from '../helper/globaljs';
import { Trans } from '@lingui/macro';

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public refresh() {
        window.location.reload(false);
    }

    public render() {
        return (
            <header>
                {
                    //<Container className="mb-4">
                    //    <div className="navbar navbar-light bg-light">
                    //        <div className="col-sm-10">
                    //            <button type="button" className="btn navbar-brand font-weight-bold" onClick={() => this.refresh()}>{Helper.getApplicationName()}</button>
                    //        </div>
                    //    </div>
                    //</Container>
                }

                {
                    <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                        <Container>
                            <NavbarBrand tag={Link} to="/">{Helper.getApplicationName()}</NavbarBrand>
                            <NavbarToggler onClick={this.toggle} className="mr-2"/>
                            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                                <ul className="navbar-nav flex-grow">
                                    {
                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to={"/clipboard"}><Trans>clipboard</Trans></NavLink>
                                        </NavItem>
                                    }
                                </ul>
                            </Collapse>
                        </Container>
                    </Navbar>
                }
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
